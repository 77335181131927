import { render, staticRenderFns } from "./DraggedElement.vue?vue&type=template&id=ca95fe84&scoped=true&"
import script from "./DraggedElement.vue?vue&type=script&lang=js&"
export * from "./DraggedElement.vue?vue&type=script&lang=js&"
import style0 from "./DraggedElement.vue?vue&type=style&index=0&id=ca95fe84&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca95fe84",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/nde/pim/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ca95fe84')) {
      api.createRecord('ca95fe84', component.options)
    } else {
      api.reload('ca95fe84', component.options)
    }
    module.hot.accept("./DraggedElement.vue?vue&type=template&id=ca95fe84&scoped=true&", function () {
      api.rerender('ca95fe84', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "modules/@ergonode/ui/src/components/DraggedElement/DraggedElement.vue"
export default component.exports